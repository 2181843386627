import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Layout from "../../components/layout"
import Button from "../../components/button"
import FeaturedImg from "../../components/featuredImg"
import Breadcrumbs from "../../components/breadcrumbs"
import pages from "../../data/pages"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      featuredImgDesktop: file(
        relativePath: { eq: "20-MIM-0008_Secondary_CA-About-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredImgMobile: file(
        relativePath: { eq: "20-MIM-0008_Mobile-12.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Layout className="secondary-page">
    <Breadcrumbs currentItem={pages[2]}></Breadcrumbs>
    <Container className="main-content">
      <FeaturedImg data={data} title="About Us" ></FeaturedImg>
      <section style={{ marginBottom: '16rem' }}>
        <article>
          <p>Created from humble Midwest beginnings in 2012, MidMichigan Ag Service is a family-owned and operated hauling and application business. What started out as a single tractor and tanker, has grown into the number one manure handling service in the state of Michigan. We strive to ensure our customers are satisfied and being environmentally responsible.</p>
          <p>We achieve these results by investing in top-tier equipment and offering fair pricing for our services. For our tankers and wagons, we charge a reasonable rate by the hour, no matter how far we haul. Our drag hose services are priced per gallon based on distance (there is a surcharge for sand).</p>

          <Button style={{ width: "fit-content" }} label="SEE WHAT PEOPLE ARE SAYING!" to="/testimonials" className="smaller-text-mobile"></Button>
        </article>
      </section>
    </Container>
  </Layout>
}

export default AboutUs