import { Link } from "gatsby"
import React from "react"
import { wrapper } from './button.module.scss'
import { ReactComponent as Arrow } from "./caret-right-solid.svg"

const Button = ({ className, style, to, label }) =>
    <Link
        className={wrapper + " " + className || ""}
        style={style || null} to={to}>{label} <Arrow></Arrow>
    </Link>

export default Button